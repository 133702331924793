<template>
  <div style="background: rgba(247, 247, 247, 1);padding-right: 40px">
    <div class="title" style="position: relative">
      <img class="bg" src="../../../../assets/img/fresh-registration-details-bg.png" alt=""/>
      <div class="title-title">
        <div style="margin-top: 19px;margin-left: 24px">学生信息</div>
      </div>
      <div class="title-bottom flex flex-wrap">
        <div class="box box-2"><span>姓名</span>
          {{ dataCont.student_name }}
          <img v-if="dataCont.student_gender == '男'" alt="默认头像" class="gender-icon" src="@/assets/img/boy.png"/>
          <img v-else alt="" class="gender-icon" src="@/assets/img/girl.png"/>
        </div>
        <div class="box box-2"><span>学号</span>{{ dataCont.student_no }}</div>
        <div class="box box-2"><span>行政班</span>{{ dataCont.class_name }}</div>
        <div class="box box-2"><span>班主任</span>{{ dataCont.teacher_name }}</div>
        <div class="box box-2"><span>校区</span>{{ dataCont.school_name }}</div>
        <div class="box box-2"><span>选科开始时间</span>{{ dataCont.operation_start }}</div>
      </div>
    </div>

    <div class="title" style="position: relative">
      <div class="title-title">
        <div style="margin-top: 19px;margin-left: 24px">成绩信息</div>
      </div>
      <p class="notice">选科参考考试：{{ dataCont.exam_name }}</p>
      <div class="title-bottom">
        <div class="mark-box flex">
          <div class="mark-cell f-bold">
            <div>科目</div>
            <div>分数</div>
            <div>校排名</div>
          </div>
          <div class="mark-cell">
            <div>总成绩</div>
            <div>{{ dataCont.sum_score }}</div>
            <div>{{ dataCont.sum_rank }}</div>
          </div>
          <div class="mark-cell" v-for="item in dataCont.subjects">
            <div>{{ item.subject }}</div>
            <div>{{ item.score }}</div>
            <div>{{ item.rank }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="title" style="position: relative">
      <div class="title-title">
        <div style="margin-top: 19px;margin-left: 24px">选科信息</div>
      </div>
      <p v-if="dataCont.baseline" class="notice notice-red" style="margin-top: 10px">
        物理成绩低于{{ dataCont.baseline.chemical }}分，不建议选择，化学成绩低于{{ dataCont.baseline.physics }}分，不建议选择</p>
      <div class="title-bottom">
        <div>
          <el-button v-for="(item, index) in dataCont.course_selection" :type="index === active ? 'primary': ''"
                     @click="onChange(index)">第{{ item.daxie }}次选科
          </el-button>
        </div>
        <div v-if="dataCont.course_selection" class="f-14 mt-20" style="color: rgba(0,0,0,.8)">
          <div class="box3-cell f-bold"><span>推荐科目组合：</span>
            <p>{{ dataCont.course_selection[active].recommend_assemble }}</p></div>
          <div class="box3-cell f-bold"><span>自选科目组合：</span>
            <p>{{ dataCont.course_selection[active].custom_assemble }}</p></div>
          <div class="box3-cell"><span>自选科目时间：</span>
            <p>{{ dataCont.course_selection[active].created_at }}</p></div>
          <div class="box3-cell"><span>自选科目次数：</span>
            <p>{{ dataCont.course_selection[active].hit }}</p></div>
          <div class="box3-cell"><span>自选推荐组合是否一致：</span>
            <p>{{ dataCont.course_selection[active].is_same }}</p></div>
          <div v-for="(item, index) in dataCont.course_selection[active].details" style="margin-bottom: 20px">
            <div class="box3-cell"><span>自选科目{{ index === 0 ? '一' : (index === 1 ? '二' : '三') }}：</span>
              <p>{{ item.subject_name }}</p></div>
            <div class="box3-cell"><span>选科理由：</span>
              <p>{{ item.remark }}</p></div>
          </div>
        </div>
      </div>
    </div>

    <div class="button" style="margin-top: 76px">
      <el-button @click="$router.back()">返回</el-button>
      <el-button type="primary" @click="visible = true">审核</el-button>
    </div>

    <el-dialog :visible.sync="visible" title="学生选科审核" width="80%" @close="visible = false">
      <div>
        <el-form ref="form" :model="formData" label-width="200px" :rules="rules">
          <el-form-item label="学生当前选科组合是否合理" prop="wise">
            <el-select :popper-append-to-body="false" v-model="formData.wise">
              <el-option label="合理" value="1"/>
              <el-option label="不合理" value="2"/>
            </el-select>
          </el-form-item>
          <el-form-item v-if="formData.wise === '2'" label="学生调整选科组合意愿" prop="again">
            <el-select :popper-append-to-body="false" v-model="formData.again">
              <el-option label="学生愿意调整选科组合" value="2"/>
              <el-option label="学生不愿意调整选科组合" value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item label="学生选科信息反馈" prop="comment">
            <t-textarea v-model="formData.comment" show-word-limit overflow maxlength="500" rows="8"></t-textarea>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="onClick">
          {{ (formData.wise === '1' || formData.again == 1) ? '选科结果存档' : '学生重新填写' }}
        </el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import Item from "@/views/finance/enrollment/payment-progress/components/Item.vue";
import ListTemplate from "@/components/pages/List.vue"
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"check","meta":{"title":"审核"}}},
  components: {ListTemplate, Item},
  data() {
    return {
      OSS_URL: process.env["VUE_APP_URL_OSS"],
      dataCont: {},
      active: 0,
      visible: false,
      formData: {
        wise: null,
        again: null,
        comment: null,
      },
      rules: {
        wise: [{required: true, message: '请选择', trigger: 'change'}],
        again: [{required: true, message: '请选择', trigger: 'change'}],
        comment: [{required: true, message: '请输入', trigger: 'change'}],
      },
    }
  },

  created() {
    this.$_axios2.get('api/pre-course-select/pc/apply-details?id=' + this.$route.query.id).then(res => {
      if (res.data.status === 0) {
        let data = res.data.data
        if (data.course_selection.length) {
          this.active = data.course_selection.length - 1;
          data.course_selection.forEach((item, index) => {
            item.daxie = tools.numberToChinese(index + 1)
          })
        }
        this.dataCont = data;
      }
    })
  },

  methods: {
    onChange(e) {
      this.active = e
    },

    onClick() {
      this.$refs.form.validate((res, e, p) => {
        if (res) {
          let params = {
            id: this.$route.query.id,
            wise: this.formData.wise,
            comment: this.formData.comment
          }
          if (this.formData.wise == 2) {
            params.again = this.formData.again
          }
          this.$_axios2.post("api/pre-course-select/pc/approve", params).then(res => {
            if (res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '审核成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1);
                }
              });
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  background: #fff;
}

.title-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #F7F7F7;
  border-radius: 10px 10px 0 0;
  background: #FFF;
  box-shadow: 2px 2px 2px 1px rgba(232, 237, 255, 0.16);
}

.title-bottom {
  box-sizing: border-box;
  margin-bottom: 24px;
  //padding-top: 28px;
  padding-bottom: 24px;
  padding-left: 32px;
  border-radius: 0 0 10px 10px;
  background: #FFF;
}

.body {
  margin: 24px 0;

  & .body-title {
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    height: 78px;
    padding-top: 19px;
    padding-left: 24px;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid #F7F7F7;
    border-radius: 10px 10px 0 0;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(232, 237, 255, 0.16);
  }

  & .body-bottom {
    box-sizing: border-box;
    min-height: 148px;
    padding: 0 24px 24px;
    border-radius: 0 0 10px 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(232, 237, 255, 0.16);

    & .body-bottom-label {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14px;
      font-weight: 400;
      width: 96px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 220px;
  height: 60px;
}

.box-1 {
  width: 25%;
}

.box-2 {
  width: 33.3%;
}

.box-3 {
  width: 50%;
}

.box {
  font-size: 14px;
  color: rgba(0, 0, 0, .6);
  margin-top: 24px;
  display: flex;
  align-items: center;

  span {
    margin-right: 30px;
    color: rgba(0, 0, 0, .8);
  }
}

.gender-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.mark-box {
  text-align: center;
  font-size: 14px;
  padding-right: 40px;

  .mark-cell {
    flex: 1;
    min-width: 60px;
    max-width: 100px;
    margin-left: -1px;

    & > div {
      width: 100%;
      height: 36px;
      line-height: 36px;
      border: 1px solid #ccc;
      margin-top: -1px;
    }
  }
}

.notice {
  font-size: 14px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, .6);
  background: transparent;
  padding-left: 32px;
}

.notice-red {
  color: red;
}

.box3-cell {
  margin-bottom: 20px;
  display: flex;
  padding-right: 40px;

  span {
    display: inline-block;
    text-align: right;
    width: 194px;
    min-width: 194px;
    margin-right: 20px;
  }
}
</style>
